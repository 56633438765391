/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Col } from "react-bootstrap"
const Layout = ({ children, pageFree, pageInfo, thankPage }) => (

  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0">
          <div className="wrappe-background-hlg">
            <div className="accent-left"></div>
            <div className="accent-right"></div>
            <div className="wrappe-line-white">
              {children}
            </div>
          </div>
          <Col className="footer-col">
            <footer>
              <span>
                © {new Date().getFullYear()}, Built with
                {` `}
                Jose Learning Center
              </span>
            </footer>
          </Col>
        </Container>
      </>
    )}
  />
)

export default Layout
