import React from "react"
import { Row, Col, Container} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component{
  
  
  render(){
    
    return(
      <Layout pageFree={{ pageName: "index" }}>
      <SEO title="Home" keywords={[`Happy Love Guide`, `Love Coach`, `Jose Learning Center`]} />
      <Container>

            <Row className="justify-content-center">
                <Col>
                    <h1 className="comingsoon">PO Buku Happy Love Guide Berakhir</h1>
                </Col>  
            </Row>
            

       </Container>
        
      </Layout>
    )
  }
}

export default IndexPage


